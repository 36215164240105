import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";
import {useQuery} from "react-query";

import useAxiosPrivate from "hooks/use_BE_API_Private";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useAuth from "hooks/useAuth";
import {
	IGetProductsRequest,
	IProduct,
	IProductSimulation,
	IProductTechView,
	ProductData,
	UniquePerformances
} from "types";

import {Header} from "components/header/header";
import Footer from "components/footer/footer";
import {Button} from "../../components/button/button";
import VariantsBlock from "./assetBlock/VariantsBlock";
import chroma from "chroma-js";
import FullscreenProduct3D from "./fullscreenImage/fullscreenProduct3D";
import Picture from "../../components/picture/picture";
import {IResponse} from "../../types/api";
import {TypeUrl, ICategory, ICategoryWebBundle, TypeDownload, FileDescriptor} from "../../types/categories";
import {uniqueBy} from "../../utils/arrayUtils";
import FullscreenDownload from "../../components/fullscreenDownload/fullscreenDownload";
import useLogout from "../../hooks/useLogout";
import {removeOrderPrefix} from "../../utils/stringUtils";
import DialogAddToCart from "../../components/dialogAddToCart/dialogAddToCart";
import useCanShowCart from "../../hooks/useCanShowCart";
import {useDebounce} from "../../utils/debounce";



const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	
	width: 100%;
	max-width: 100%;
	min-height: 100%;
	height: 100%;
	
	overflow-y: auto;

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
		scrollbar-gutter: auto;
	}
`;

const ImageWrapper = styled.div<{imageSrc: string}>`
	position: relative;
	
	min-height: 620px;
	height: 620px;
	width: 100%;
	//max-width: 1440px;
	
	background-image: url(${props => props.imageSrc});
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	background-position-y: center;
	
	padding: 30px 20px;
	
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const TriangleDiv = styled.div`
	position: absolute;
	
	width: 330px;
	height: 330px;
	
	bottom: 0px;
	left: 0px;
	
`;

const ReverseLabel = styled.div`
	position: absolute;
	user-select: none;

	bottom: 9px;
	left: 20px;
`;

const Row = styled.div`
	width: 100%;
	
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const RowCollapsible = styled.div`
	width: 100%;
	
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media screen and (max-width: 704px) {
		flex-direction: column;
		gap: 30px;
	}
`;

const RowRight = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

const RowRightCollapsible = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-wrap: wrap;
	
	@media screen and (max-width: 704px) {
		justify-content: flex-start;
	}
`;

const DescriptionWrapper = styled.div`
	flex: 1 0 auto;
	width: 100%;
	max-width: 1200px;
	padding: 30px 30px 0px 30px;
	
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

const DescriptionRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	
	gap: 5px;
`;

const ModelLabel = styled.div`
	border: 1px solid ${props => props.theme.TextLight};
	border-radius: 4px;

	display: flex;
	align-items: center;
	padding: 4px;

	user-select: none;
	cursor: pointer;

	transition: background-color 250ms ease-out;
	
	&:hover {
		background-color: ${chroma("#FFFFFF").darken(0.3).css()};
		//opacity: 0.6;
	}
`;

const ColumnLeft = styled.div`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	
	gap: 15px;
`;

const ColumnRight = styled.div`
	flex: 1 0 auto;
	@media screen and (max-width: 704px) {
		flex: 1 0 auto;
	}
	
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	gap: 15px;
`;

const ColumnRightUniquePerf = styled(ColumnRight)`
	color: ${props => props.theme.TextDefault};
	width: 360px;
	max-width: 360px;
	
	@media screen and (max-width: 704px) {
		justify-content: flex-start;
	}
`;

const SpanUniquePerf = styled.span`
	color: ${props => props.theme.TextDefault};
	text-align: end;

	@media screen and (max-width: 704px) {
		text-align: start;
	}
`;

const UniquePerformanceWrapper = styled.div`
	position: relative;
	
	min-width: 60px;
	width: 60px;
	max-width: 60px;
	
	min-height: 60px;
	height: 60px;
	max-height: 60px;

	& > *:nth-child(2) {
		display: none;
	}

	&:hover > *:nth-child(2) {
		display: block;
	}
`;

const UniquePerformanceLabel = styled.div`
	position: absolute;
	bottom: 60px;
	
	padding: 3px 4px;
	border-radius: 3px;
	background-color: ${props => props.theme.Gray5};
	
	disply: flex;
	align-items: center;
	text-align: center;
	flex-direction: row;
`;

export const Product = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	const { auth } = useAuth();
	const Private_BE_API = useAxiosPrivate();
	const logout = useLogout();
	const canShowCart = useCanShowCart();

	const [selectedModel, setSelectedModel] = useState<ICategoryWebBundle | null>(null);
	function SelectModel(newSelectedModel: ICategoryWebBundle) : void {
		setSelectedModel(newSelectedModel);
	}
	function SelectFirstModelWithCode(code: string) : void {
		const firstModel = categoryWebBundles?.find( webBundle => webBundle.Code === code);
		if(firstModel) {
			setSelectedModel(firstModel);
		}
	}

	const [searchParams, useSearchParam] = useSearchParams();

	const variantRefIdQueryValue = searchParams.get("variantRefId");
	const validateVariantRefId = (value?: string | null): string | null => {
		return value && value !== "" ? value : null;
	};

	const [searchValue, setSearchValue] = useState<string>("");
	const debouncedSearch = useDebounce(searchValue, 500);
	useEffect( () => {
		if(debouncedSearch.length > 0) {
			navigate(`search?searchV=${debouncedSearch}`);
		}
	}, [debouncedSearch])

	const [selectedVariant, setSelectedVariant] = useState<ProductData | null | undefined>(undefined);
	useEffect(() => {
		//console.warn("update url", selectedVariant);
		if(selectedVariant !== undefined) {
			//console.warn("really updated url", selectedVariant);
			searchParams.set("variantRefId", selectedVariant?.IdReference ?? "");
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useSearchParam(searchParams, {replace: true});
		}
	}, [selectedVariant]);
	function SelectVariant(newSelectedVariant: IProduct) : void {
		setSelectedVariant(newSelectedVariant);
		//console.error("SelectVariant",newSelectedVariant );
	}
	function SelectVariantByIdRef(productS : ProductData[], idRef: string) : boolean {
		const newSelectedVariant = productS?.find(product => product.IdReference === idRef)
		//console.error("SelectVariantByIdRef",productS, idRef, newSelectedVariant );
		setSelectedVariant(newSelectedVariant ?? null);
		return !!newSelectedVariant;
	}

	const { externalProductId } = useParams();
	const [downloadFileDescriptors, setDownloadFileDescriptors] = useState<FileDescriptor<TypeDownload>[]>([]);
	const [showDetails, setShowDetails] = useState(false);
	const [showFrontImage, setShowFrontImage] = useState(true);
	const [showAddToCartDialog, setShowAddToCartDialog] = useState(false);

	const { data: category} = useQuery(
		["ProductQuery", auth!.succeeded, externalProductId],
		async () => {

			try {
				const {data, status} = await Private_BE_API.get<IResponse<ICategory>>(`/Fabric/GetCategoryByIdReference/${externalProductId}`);
				console.log("categoryByRef:" + JSON.stringify(data, null, 4));

				if(status === 204) {
					navigate("/404");
				}

				data.Data.Name = removeOrderPrefix(data.Data.Name);
				return data.Data;
			}
			catch (e : any) {
				console.warn("/Fabric/GetCategories returned error:", e);
				if(e.response.status === 401)
				{
					console.error("Token expired: logout");
					logout();
				}

				return null;
			}
		},
		{ enabled: (!!auth?.succeeded && !!externalProductId) }
	);

	const { data: categoryWebBundles } = useQuery(
		["categoryWebBundles", auth!.succeeded, category?.IdCategory],
		async () /*Promise<ICategoryWebBundle[] | undefined>*/ => {

			try {
				const {data: webBundles} = await Private_BE_API.get<IResponse<ICategoryWebBundle[]>>(`/Fabric/GetCategoryWebBundles/${category?.IdCategory}`);
				console.log("categoryWebBundles:" + JSON.stringify(webBundles, null, 4));

				//webBundles.Data.Name = removeOrderPrefix(webBundles.Data.Name);
				return webBundles.Data;
			}
			catch (e) {
				return null;
			}
		},
		{ enabled: (!!auth?.succeeded && !!category?.IdCategory) }
	);

	useEffect( () =>
	{
		if(categoryWebBundles?.[0])
		{
			setSelectedModel(categoryWebBundles?.[0]);
		}
	}, [categoryWebBundles]);


	const ProductsQuery = async () : Promise<ProductData[]> => {
		const payload: IGetProductsRequest = {
			Categories: [category!.IdCategory],
			State: "Approved",
			PageFilter: {
				Page: 0,
				Length: 999
			}
		};

		const { data } = await Private_BE_API.post<IResponse<IProduct[]>>("/Fabric/GetProducts", payload);
		const products : ProductData[] = [];

		for(const productBase of data.Data)
		{
			const product : ProductData = Object.assign({techView: undefined}, productBase);
			product.Name = removeOrderPrefix(product.Name);

			/*
			//const { data: techView } = await Private_BE_API.get<IResponse<IProductTechView>>(`/Fabric/Techview/${product.IdProduct}`);
			//product.techView = techView.Data;

			const resultTechview = Private_BE_API.get<IResponse<IProductTechView>>(`/Fabric/Techview/${product.IdProduct}`);
			product.resultTechview = resultTechview;

			//const { data: simulation } = await Private_BE_API.get<IResponse<IProductSimulation>>(`/Fabric/Simulation/${product.IdProduct}`);
			//product.simulation = simulation.Data;

			const resultSimulation = Private_BE_API.get<IResponse<IProductSimulation>>(`/Fabric/Simulation/${product.IdProduct}`);
			product.resultSimulation = resultSimulation;

			//Euro 2.0
			//Base product textures if present
			if(product.BasesIds.length > 0) {
				const baseProductId = product.BasesIds[0];
				const resultBaseProductSimulation = Private_BE_API.get<IResponse<IProductSimulation>>(`/Fabric/Simulation/${baseProductId}`);
				product.baseProductResultSimulation = resultBaseProductSimulation;
			}*/

			products.push(product);
		}

		/*for(const product of products) {

			product.techView = (await (product.resultTechview!)).data.Data;

			if(product.baseProductResultSimulation)
			{
				product.simulation = (await (product.baseProductResultSimulation!)).data.Data;
				const overrideSimulation = (await (product.resultSimulation!)).data.Data;
				Object.assign(product.simulation, overrideSimulation);
			}
			else {
				product.simulation = (await (product.resultSimulation!)).data.Data;
			}
		}*/

		console.log("Products:" + JSON.stringify(products, null, 4));
		return products;
	};

	const {
		isLoading,
		data: products,
		error,
		isFetching,
	} = useQuery(
		["GetProducts", auth!.succeeded, category?.IdCategory],
		ProductsQuery,
		{ enabled: (!!auth?.succeeded && !!category?.IdCategory)/*, keepPreviousData: true, staleTime: /*10000*/ }
	);

	useEffect( () =>
	{
		if(products && selectedVariant === undefined) {
			const validatedQueryValue = validateVariantRefId(variantRefIdQueryValue);

			//console.warn("first init:" + validatedQueryValue);
			let result = false;
			if(validatedQueryValue) {
				result = SelectVariantByIdRef(products, validatedQueryValue);
			}

			if(!result && products?.[0]) {
				setSelectedVariant(products[0]);
			}
		}
	}, [products]);

	const ProductSubQueries = async () : Promise<ProductData> => {

			const productBase = selectedVariant!;

			const product : ProductData = Object.assign({techView: undefined}, productBase);
			product.Name = removeOrderPrefix(product.Name);


			const { data: techView } = await Private_BE_API.get<IResponse<IProductTechView>>(`/Fabric/Techview/${product.IdProduct}`);
			product.techView = techView.Data;

			//const resultTechview = Private_BE_API.get<IResponse<IProductTechView>>(`/Fabric/Techview/${product.IdProduct}`);
			//product.resultTechview = resultTechview;

			const { data: simulation } = await Private_BE_API.get<IResponse<IProductSimulation>>(`/Fabric/Simulation/${product.IdProduct}`);
			product.simulation = simulation.Data;

			//const resultSimulation = Private_BE_API.get<IResponse<IProductSimulation>>(`/Fabric/Simulation/${product.IdProduct}`);
			//product.resultSimulation = resultSimulation;

			//Euro 2.0
			//Base product textures if present
			if(product.BasesIds.length > 0) {
				const baseProductId = product.BasesIds[0];
				//const resultBaseProductSimulation = Private_BE_API.get<IResponse<IProductSimulation>>(`/Fabric/Simulation/${baseProductId}`);
				//product.baseProductResultSimulation = resultBaseProductSimulation;

				const resultBaseProductSimulation = await Private_BE_API.get<IResponse<IProductSimulation>>(`/Fabric/Simulation/${baseProductId}`);
				const overrideSimulation = product.simulation;
				product.simulation = resultBaseProductSimulation.data.Data;
				Object.assign(product.simulation, overrideSimulation);
			}


		/*for(const product of products) {

			product.techView = (await (product.resultTechview!)).data.Data;

			if(product.baseProductResultSimulation)
			{
				product.simulation = (await (product.baseProductResultSimulation!)).data.Data;
				const overrideSimulation = (await (product.resultSimulation!)).data.Data;
				Object.assign(product.simulation, overrideSimulation);
			}
			else {
				product.simulation = (await (product.resultSimulation!)).data.Data;
			}
		}*/

		console.log("Products:" + JSON.stringify(products, null, 4));
		return product;
	};

	const {
		//isLoading,
		data: selectedProductSubData,
		//error,
		//isFetching,
	} = useQuery(
		["GetProductSubQueries", auth!.succeeded, category?.IdCategory, selectedVariant?.IdProduct],
		ProductSubQueries,
		{ enabled: (!!auth?.succeeded && !!category?.IdCategory && !!selectedVariant)/*, keepPreviousData: true, staleTime: /*10000*/ }
	);

	useEffect( () =>
	{
		if(selectedProductSubData && selectedVariant) {
			selectedVariant.techView = selectedProductSubData?.techView;
			selectedVariant.simulation = selectedProductSubData?.simulation;

			setSelectedVariant({...selectedVariant});
		}
	}, [selectedProductSubData]);


	function CanNavigateToTechSheet() : boolean {
		const fileToDownload =
			selectedVariant?.Urls?.find(fileDescriptor => fileDescriptor.Name === "Techsheet")
			|| category?.Urls?.find(fileDescriptor => fileDescriptor.Name === "Techsheet");
		return !!(fileToDownload && fileToDownload.Url !== "");
	}

	function NavigateToTechSheet() : void {
		const fileToDownload =
			selectedVariant?.Urls?.find(fileDescriptor => fileDescriptor.Name === "Techsheet")
			|| category?.Urls?.find(fileDescriptor => fileDescriptor.Name === "Techsheet");
		if (fileToDownload && fileToDownload.Url !== "") {
			window.location.href = fileToDownload.Url;
		}
	}

	function CanShowDialogDownloadFile() : boolean {
		const result : boolean =
			(selectedVariant?.Files?.length && selectedVariant?.Files?.length > 0)
			|| (category?.Files?.length && category?.Files?.length > 0)
			|| false
		return result;
	}

	function ShowDialogDownloadFile() : void {
		const copyFiles : FileDescriptor<TypeDownload>[] = Object.assign([], category?.Files);
		//Do override
		if(selectedVariant?.Files) {
			for (const variantFile of selectedVariant.Files) {
				let findEl = copyFiles.find(file => file.Name === variantFile.Name);
				if(findEl) {
					Object.assign(findEl, variantFile);
				}
				else {
					copyFiles.push(variantFile);
				}
			}
		}
		//console.warn(copyFiles);
		setDownloadFileDescriptors(copyFiles.length > 0 ? copyFiles : []);
	}

	const goHome = () => {
		if((category?.Path.length ?? 0) > 1){
			const parentCatRefId = category!.Path[category!.Path.length-2].IdReference;
			navigate(`/products/${parentCatRefId}?scrollTo=${externalProductId}`);
		}
		else {
			navigate(`/categories`);
		}
	};

	function UniquePerfToFile(uniquePerf: UniquePerformances): string {
		const basePath = "/assets/UniquePerformances/";

		switch (uniquePerf) {
			case UniquePerformances.Breathability:
				return basePath + "Breathability.png";
			case UniquePerformances.ChlorineResistance:
				return basePath + "ChlorineResistance.png";
			case UniquePerformances.EasyCare:
				return basePath + "EasyCare.png";
			case UniquePerformances.ExtraConfort:
				return basePath + "ExtraConfort.png";
			case UniquePerformances.ExtraFine:
				return basePath + "ExtraFine.png";
			case UniquePerformances.NoPilling:
				return basePath + "NoPilling.png";
			case UniquePerformances.QuickDrying:
				return basePath + "QuickDrying.png";
			case UniquePerformances.SunBlock:
				return basePath + "SunBlock.png";
			case UniquePerformances.WaterProof:
				return basePath + "WaterProof.png";
			case UniquePerformances.WaterRepellent:
				return basePath + "WaterRepellent.png";
			case UniquePerformances.WrinkleFree:
				return basePath + "WrinkleFree.png";
			default:
				return "";
		}
	}

	function ModelCodeToString(modelCode: string): string {
		switch (modelCode) {
			case "SPO":
				return "SPORTSWEAR";
			case "REA":
				return "READY TO WEAR";
			case "SWI":
				return "SWIMWEAR";
			case "UND":
				return "UNDERWEAR";
			default:
				return modelCode;
		}
	}

	const ModelsCodeList = /*useMemo(*/() => {
		return (
			< >
				{uniqueBy<ICategoryWebBundle>(categoryWebBundles || [],
					(a, b) => a.Code === b.Code
				).map((webBundle, index) => (
					<ModelLabel key={`ModelLabel_${webBundle.Name}_${index}`} className={"ptext-xl caps"} style={{color: theme.TextDefault}}
								onClick={() => {
									SelectFirstModelWithCode(webBundle.Code);
									setShowDetails(true);
								}}
					>{ModelCodeToString(webBundle.Code)}</ModelLabel>
				))}
			</>
		);}/*, [isLoading, isFetching, selectedVariant])*/;

	const UniquePerformacesList = /*useMemo(*/() => {
		return (
			< >
				{selectedVariant?.techView?.TechProperties
					?.sort((a, b) => a > b ? 1 : -1)
					. map((uniquePerformance, index) => (
					<UniquePerformanceWrapper key={`id_${uniquePerformance}_${index}`}>
						<Picture src={UniquePerfToFile(uniquePerformance)} useSpinner={false} usePlaceholder={false}/>
						<UniquePerformanceLabel>
							<p className={"ptext-s caps"} style={{color: theme.Gray0}}>{t(uniquePerformance)}</p>
						</UniquePerformanceLabel>
					</UniquePerformanceWrapper>
				))}
			</>
		);}/*, [isLoading, isFetching, selectedVariant])*/;

	return (
		<Wrapper>
			<Header onSearchValueChange={setSearchValue} /*crumbs={crumbs}*/ searchValue={searchValue} showSearch />
				<ImageWrapper imageSrc={ (showFrontImage ? selectedVariant?.PreviewImage : selectedVariant?.PreviewReverse) || "" }>
					{/*<Picture src={"/testAssets/productFront.jpg"} />*/}
					<Row>
						<Button onClick={/*() => history.back()*/ goHome} isOutlined={true} leftIcon={"back"} label={t("Back")}></Button>
						<Button onClick={() => setShowDetails(true)} isOutlined={true} leftIconSrcOverride={"/assets/viewIn3d.svg"} label={t("ViewIn3D")} />
					</Row>
					<RowRight>
						<VariantsBlock selectedVariant={selectedVariant ?? null} onChange={SelectVariant} variants={products || []}></VariantsBlock>
					</RowRight>
					<RowCollapsible>
						{CanShowDialogDownloadFile() &&
							<Button isOutlined={true} leftIconSrcOverride={"/assets/3d.svg"} iconWidth={"22px"} label={t("Download3dFiles")} size={"Large"}
									onClick={() => {ShowDialogDownloadFile()}}
									disabled={!CanShowDialogDownloadFile()}
							></Button>
						}
						{
							(selectedVariant?.PreviewReverse && selectedVariant?.PreviewReverse !== "") &&
								<Button isOutlined={true} leftIconSrcOverride={"/assets/rightArrow.svg"} leftIconFlipHoriz iconWidth={"14px"} label={showFrontImage ? t("Reverse") : t("Front")} size={"Large"}
										onClick={() => {setShowFrontImage(!showFrontImage);}}
										disabled={!(selectedVariant?.PreviewReverse && selectedVariant?.PreviewReverse !== "")}
								></Button>
						}
					</RowCollapsible>

					{/*<TriangleDiv>
						<svg width="330" height="330">
							<mask id="svgmask1">
								<polygon fill="#ffffff" points="0,30 300,330 0,330"></polygon>
							</mask>
							<image href="/testAssets/productFront.png" mask="url(#svgmask1)"></image>
							<line x1="-30" y1="0" x2="330" y2="360" style={{stroke: "rgb(255,255,255)", strokeWidth: 3, filter: "drop-shadow(0px -4px 4px rgb(0 0 0 / 0.4))"}} />
						</svg>
					</TriangleDiv>
					<ReverseLabel>
						<p className={"heading-xl caps"} style={{color: "rgba(0, 0, 0, 0.25)"}}>{t("Reverse")}:</p>
					</ReverseLabel>*/}

				</ImageWrapper>
				<DescriptionWrapper>

					<RowCollapsible style={{gap: "30px"}}>
						<ColumnLeft style={{flex: "1 1 auto"}}>
							<p className={"heading-xxl caps"} style={{color: theme.TextDark}} dangerouslySetInnerHTML={{__html: category?.Name || ""}}></p>
							<p className={"heading-l caps"} style={{color: theme.TextDefault}} dangerouslySetInnerHTML={{__html:
								`${category?.Description || ""}${category?.Description && (selectedVariant?.Description || selectedVariant?.Name) ? ", ": ""}${selectedVariant?.Description || ""}${selectedVariant?.Name || ""}`
							}}></p>
						</ColumnLeft>
						{ canShowCart &&
							<ColumnRight style={{justifyContent: "center" /*alignItems: "flex-end"*/}}>
								<Button backgroundColor={"Gray2"} leftIconSrcOverride={"/assets/cart.svg"} label={t("AddToCart")} size={"Compact"}
										onClick={() => {setShowAddToCartDialog(true)}}
										disabled={false}
										//overrideWidth={"220px"}
								></Button>
							</ColumnRight>
						}
					</RowCollapsible>

					<RowCollapsible style={{padding: "30px 0px"}}>
						<Button backgroundColor={"Gray2"} leftIconSrcOverride={"/assets/pdf.svg"} label={t("TechnicalSheet")} size={"Compact"}
								onClick={() => {NavigateToTechSheet()}}
								disabled={!CanNavigateToTechSheet()}
						></Button>
						<RowRightCollapsible style={{gap: "5px"}}>
							{/*<ModelLabel className={"ptext-xl caps"} style={{color: theme.TextDefault}}>Underwear</ModelLabel>
							<ModelLabel className={"ptext-xl caps"} style={{color: theme.TextDefault}}>Swimwear</ModelLabel>
							<ModelLabel className={"ptext-xl caps"} style={{color: theme.TextDefault}}>SportWear</ModelLabel>*/}
							{ModelsCodeList()}
						</RowRightCollapsible>
					</RowCollapsible>
					<RowCollapsible>
						<ColumnLeft>
							<DescriptionRow>
								<span className={"heading-xs caps"} style={{color: theme.TextDefault}}>{t("Composition")}:</span>
								<span className={"ptext-l"} style={{color: theme.TextDefault}}>{selectedVariant?.techView?.Code}</span>
							</DescriptionRow>
							<DescriptionRow>
								<span className={"heading-xs caps"} style={{color: theme.TextDefault}}>{t("Weight")}:</span>
								<span className={"ptext-l"} style={{color: theme.TextDefault}}>{selectedVariant?.techView?.Weight}</span>
							</DescriptionRow>
							<DescriptionRow>
								<span className={"heading-xs caps"} style={{color: theme.TextDefault}}>{t("Height")}:</span>
								<span className={"ptext-l"} style={{color: theme.TextDefault}}>{selectedVariant?.techView?.Width}</span>
							</DescriptionRow>
						</ColumnLeft>
						<ColumnRightUniquePerf>
							<SpanUniquePerf className={"heading-xs caps"}>{t("UniquePerformance")}</SpanUniquePerf>
							<RowRightCollapsible style={{gap: "22px"}}>
								{UniquePerformacesList()}
							</RowRightCollapsible>
						</ColumnRightUniquePerf>
					</RowCollapsible>
				</DescriptionWrapper>
				{ showDetails &&
						<FullscreenProduct3D requestClose={() => setShowDetails(false)}
											 models={categoryWebBundles || []} selectedModel={selectedModel} onChangeModel={SelectModel}
											 variants={products || []} selectedVariant={selectedVariant ?? null} onChangeVariant={SelectVariant}
											 onDownload3d={() => {ShowDialogDownloadFile()}}
											 download3dEnabled={CanShowDialogDownloadFile()}	/>
				}
				{ downloadFileDescriptors.length > 0 &&
					<FullscreenDownload fileDescriptors={downloadFileDescriptors} requestClose={() => setDownloadFileDescriptors([])}/>
				}
				{ showAddToCartDialog &&
					<DialogAddToCart name={(category?.Name || "")} /*productId={selectedVariant?.IdProduct}*/ categoryId={category!.IdCategory}
									 variants={products} colorFolderDefault={false} requestClose={() => setShowAddToCartDialog(false)}/>
				}
			<Footer />
		</Wrapper >
	);
};